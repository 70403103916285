import Image from "next/image";
import { useRouter } from "next/router";
import { useState } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import useFilter from "store/useFilter";
import cn from "classnames";

type MenuBtn_t = {
  label: string;
  href?: string;
  icon: string;
  dropdown?: {
    label: string;
    href?: string;
    active?: boolean;
    hidden?: boolean;
  }[];
  active?: boolean;
};

const MenuBtn = ({
  icon,
  label,
  href = "",
  dropdown,
  active = false,
}: MenuBtn_t) => {
  const [collapse, setCollapse] = useState<boolean>(active ? true : false);
  const { push } = useRouter();
  const { resetFilter } = useFilter();

  return (
    <div>
      <button
        className={cn(
          "flex items-center w-full gap-6  capitalize px-2 rounded-md text-left transition-all duration-300 hover:bg-[#8C56FF]/[0.15] hover:text-white-0",
          active ? "text-white-0 bg-[#8C56FF]/[0.15]" : "text-white-500"
        )}
        onClick={() => {
          if (dropdown) {
            setCollapse(!collapse);
          } else {
            resetFilter();
            push(href).then(() => {
              resetFilter();
            });
          }
        }}
      >
        <div className="w-[43px] h-[43px] relative">
          <Image src={icon} alt={`${label} icon`} fill />
        </div>

        <div className="flex items-center justify-between w-full">
          <p
            className={cn(
              "capitalize text-lg",
              active ? "font-semibold" : "font-bold"
            )}
          >
            {label}
          </p>
          {dropdown && (
            <i
              className={`transition-all duration-300 ${
                collapse && "rotate-90"
              }`}
            >
              <MdKeyboardArrowRight className="text-xl" />
            </i>
          )}
        </div>
      </button>

      <div
        className={`mt-3 transition-all overflow-hidden duration-500 text-white-500 bg-[#8C56FF]/[0.15] rounded-md ${
          collapse ? "max-h-[500px]" : "max-h-0 "
        } flex flex-col`}
      >
        {dropdown &&
          dropdown.map((v, i) => (
            <button
              onClick={() => {
                resetFilter();
                push(v.href ?? "#").then(() => {
                  resetFilter();
                });
              }}
              key={i}
              className={`transition-all duration-500 text-left text-lg font-semibold capitalize py-2 pl-[75px] hover:text-white-0 ${
                v.hidden && "hidden"
              } ${v.active && "text-white-0 "} `}
            >
              {v.label}
            </button>
          ))}
      </div>
    </div>
  );
};

export default MenuBtn;
