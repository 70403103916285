import { tInsentifStickerQuery } from "@/types/InsentifSticker";
import { StatusType } from "@/types/Subscription";
import { InstIitem, iItem } from "@/types/Summary";
import { create } from "zustand";

/**
 * Holds the all the necessary filter for a lot of menu
 */

export type statusFilterType = "ALL" | "active" | "will_expire" | "inactive" | "blocked";

export type AreaType = "province" | "city" | "district";

export type HariType = "senin" | "selasa" | "rabu" | "kamis" | "jumat" | "sabtu" | "minggu";

export type UserKategoriType = "registered" | "subscribed" | "company" | "shipper" | "trucker";
export type UserKategoriTypeSemua = "semua" | UserKategoriType;

export type FilterType = {
  page: number;
  per_page: number;

  search?: string;

  start_date?: Date;
  end_date?: Date;

  payment_status: "ALL" | StatusType;

  by: "asc" | "desc";
  sort: string | null;

  status: statusFilterType;
  statusCompanyActivityLog?: string;
  truck_category: number[];

  categoryId?: number;
  companyType?: ("perusahaan" | "perorangan") | string;
  companyOwnership?: string;
  packageName: string;
  packageId?: number;
  packageTypeId?: number;
  priceName: string;
  source: string;
  company: {
    name?: string;
    id?: number;
  };
  user: {
    name?: string;
    id?: number;
  };

  searchName?: string;
  searchEmail?: string;
  searchPhone?: string;
  searchId?: string | number;
  searchCompanyName?: string;
  searchLink?: string;
  searchTransaction?: string;
  searchSourceMedia?: string;
  min: string;
  max: string;

  grouping: string[];
  areaMode: AreaType;

  from_province?: string;
  from_city?: string;
  from_district?: string;

  destination_province?: string;
  destination_city?: string;
  destination_district?: string;

  payload_type?: string;

  truck_category_id?: number;
  truck_type_id?: number;

  any_destination: boolean;

  years: number;
  monthly: number;

  item: InstIitem;

  user_category?: UserKategoriTypeSemua;
  searchDay?: string;

  // qp block akun
  username_blocked?: string;
  company_blocked?: string;
  phone_blocked?: string;
  username?: string;
  sort_username_blocked?: boolean;
  sort_company_blocked?: boolean;
  sort_username?: boolean;
  sort_company?: boolean;

  // qp laporan deal iklan
  search_name_user?: string;
  search_name_partner?: string;
  id_post?: number;
  id_partner?: number;
  deal_outside?: string;

  // golek koin | laporan pembelian koin
  statusKoin?: string;
  id_company?: number;
  id_user?: number;
  phone_number?: number;
  order_date?: boolean;
  noTransaction?: string;

  // insentif koin
  insentif: tInsentifStickerQuery;

  category?: string;
  subcategory?: string;
};

const filterInitialValue: FilterType = {
  page: 1,
  per_page: 20,

  search: undefined,
  min: "",
  max: "",
  start_date: undefined,
  end_date: undefined,

  payment_status: "ALL",
  source: "",
  by: "desc",
  sort: null,

  status: "ALL",
  statusCompanyActivityLog: undefined,
  truck_category: [0],

  categoryId: undefined,
  companyType: undefined,
  packageName: "semua",
  packageId: -1,
  packageTypeId: -1,
  priceName: "semua",

  // GolekIklan
  category: "ALL",
  subcategory: "ALL",

  company: {
    name: undefined,
    id: undefined,
  },
  user: {
    name: undefined,
    id: undefined,
  },

  searchName: "",
  searchEmail: "",
  searchPhone: "",
  searchId: "",
  searchCompanyName: "",
  searchLink: "",
  searchTransaction: "",
  searchSourceMedia: "",

  grouping: [],
  areaMode: "province",

  from_province: undefined,
  from_city: undefined,
  from_district: undefined,

  destination_province: undefined,
  destination_city: undefined,
  destination_district: undefined,

  payload_type: undefined,

  truck_category_id: undefined,
  truck_type_id: undefined,

  any_destination: false,

  years: new Date().getFullYear(),
  monthly: new Date().getMonth() + 1,
  item: "app_download",

  user_category: "semua",
  searchDay: "",

  // qp block akun
  username_blocked: undefined,
  company_blocked: undefined,
  phone_blocked: undefined,
  username: undefined,
  sort_username_blocked: false,
  sort_company_blocked: false,
  sort_username: false,
  sort_company: false,

  // qp laporan deal iklan
  search_name_user: undefined,
  search_name_partner: undefined,
  id_post: undefined,
  id_partner: undefined,
  deal_outside: "ALL",

  // golek koin
  statusKoin: undefined,
  id_company: undefined,
  id_user: undefined,
  phone_number: undefined,
  order_date: undefined,
  noTransaction: undefined,

  // insentif koin
  insentif: {
    admin_action_name: undefined,
    admin_create_name: undefined,
    status: undefined,
    to_create_date: undefined,
    user_name: undefined,
    user_phone: undefined,
    sort_create_date: true,
  },
};

const stringifiedInitialValue = JSON.stringify(filterInitialValue);

// Dont
type useFilterType = {
  /**
   * WHen filter is the same as its initialValue this'll be true
   */
  isInitial: boolean;
  filter: FilterType;
  setFilter: (filter: Partial<FilterType>) => Promise<void>;
  resetFilter: () => Promise<void>;
};

const useFilter = create<useFilterType>((set) => ({
  filter: filterInitialValue,
  isInitial: true,
  setFilter: async (filter: Partial<FilterType>) =>
    await new Promise((resolve) => {
      set((state) => {
        // Reset page to 1 if theres no page on the filter
        // console.log("wot", filter, filter.page ?? 1);
        return {
          filter: { ...state.filter, ...filter, page: filter.page ?? 1 },
          isInitial:
            JSON.stringify({
              ...state.filter,
              ...filter,
              page: filter.page ?? 1,
            }) === stringifiedInitialValue,
        };
      }),
        resolve();
    }),
  resetFilter: async () =>
    await new Promise((resolve) => {
      set((state) => ({ filter: filterInitialValue, isInitial: true }));
      resolve();
    }),
}));

export default useFilter;
